import { Checkbox, Col, Row } from "antd"
import { navigate } from "gatsby"
import { useAdminLogin } from "medusa-react"
import React, { useContext, useEffect, useState } from "react"
import MedusaIcon from "../components/fundamentals/icons/medusa-icon"
import LoginLayout from "../components/loginLayout"
import SEO from "../components/seo"
import { Form, FormInstance, Input, Button } from "antd"
import { AccountContext } from "../context/account"
import { openCustomNotificationWithIcon } from "../common/notifycation"

const LoginPage = () => {
  const ref = React.createRef<FormInstance>()
  const [form] = Form.useForm()
  const login = useAdminLogin()

  const { isLoggedIn, dispatch, user } = useContext(AccountContext)

  const [isInvalidLogin, setIsInvalidLogin] = useState(false)
  const [loading, setLoading] = useState(false)

  function handleKeyUp(event) {
    // Enter
    if (event.keyCode === 13) {
      ref?.current?.submit()
    }
  }

  const handleLogin = (data) => {
    setLoading(true)
    login.mutate(
      {
        email: data.email.trim(),
        password: data.password.trim(),
      },
      {
        onSuccess: (data) => {
          dispatch({ type: "setUserInfor", payload: data.user })
        },
        onError: (err) => {
          setIsInvalidLogin(true)
          setLoading(false)
        },
      }
    )
  }

  useEffect(() => {
    if (isLoggedIn) {
      // if (user?.need_change_password == true) {
      //   openCustomNotificationWithIcon(
      //     "warning",
      //     "警告",
      //     "初回ログイン時はパスワードを変更する必要があります。"
      //   )
      //   navigate("/change-password-manual")
      //   return
      // }
      navigate("/home")
    }
  }, [isLoggedIn])

  return (
    <LoginLayout>
      <SEO title="Mr.Food" />
      <div className="flex h-full w-full items-center justify-center">
        <div className="flex min-h-[600px] bg-grey-0 rounded-rounded justify-center p-10">
          <Form
            className="flex flex-col pt-12 w-full px-[50px] items-center  "
            name="basic"
            initialValues={{}}
            onFinish={handleLogin}
            onChange={() => {
              setLoading(false)
              setIsInvalidLogin(false)
            }}
            form={form}
            ref={ref}
            onKeyUp={handleKeyUp}
            layout="vertical"
            requiredMark={false}
          >
            <MedusaIcon />
            <Row style={{ width: "100%" }}>
              <Col style={{ width: "100%" }}>
                <div className="mt-12 text-[14px]" style={{ width: "100%" }}>
                  <Form.Item
                    name="email"
                    label={<div className="font-bold">ログインID</div>}
                    rules={[
                      {
                        type: "email",
                        message: "有効なメールアドレスではありません。",
                      },
                      {
                        required: true,
                        message: "メールアドレスを入力してください。",
                      },
                    ]}
                  >
                    <Input
                      placeholder={"sample@mail.com"}
                      style={{ borderRadius: "5px", width: 320, height: 48 }}
                    />
                  </Form.Item>
                </div>
                <div className="mt-4 text-[14px]">
                  <Form.Item
                    name="password"
                    label={<div className="font-bold">パスワード</div>}
                    rules={[
                      {
                        required: true,
                        message: `パスワードを入力してください。`,
                      },
                      {
                        min: 8,
                        message: `パスワードは8文字以上である必要があります。`,
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="********"
                      style={{ borderRadius: "5px", width: 320, height: 48 }}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>

            {isInvalidLogin && (
              <span className="field_fail_input">
                メールアドレス、パスワードのいずれかに間違いがあります。
                入力内容をご確認ください。
              </span>
            )}
            <div className="mt-2 flex  flex-col">
              <div className="flex gap-1">
                <Checkbox />
                <div className="text-[14px] font-medium">
                  次回から自動でログイン
                </div>
              </div>
              <Button
                className="button_submit mt-2"
                size="large"
                htmlType="submit"
                loading={loading}
              >
                ログインする
              </Button>
              {/* <Button
                className="button_signup"
                size="large"
                onClick={() => {
                  navigate("/sign-up")
                }}
              >
                新規会員登録
              </Button> */}
            </div>
            {/* <span
              className="inter-base-regular text-[#E60020] font-bold text-[14px] mt-5 my-8 cursor-pointer"
              onClick={() => navigate("/forgot-password")}
            >
              パスワードを忘れた場合はこちら
            </span> */}
          </Form>
        </div>
      </div>
    </LoginLayout>
  )
}

export default LoginPage
